import { Box, styled, Typography } from "@mui/material";
import React from "react";

const StyledBox = styled(Box)(({ theme }) => ({
  //   borderBottom: `1px solid ${theme.palette.neutral[50]}`,
  marginBottom: "20px",
}));

const ContactUs = () => {
  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        // textAlign: "center",
      }}
    >
      <Box>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            marginBottom: "30px",
          }}
        >
          Contact Us
        </Typography>
        <StyledBox>
          <Typography variant="body1" gutterBottom>
            <strong>ARIF UDDIN</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Director, IFRD International LLP, Dakhin Udali, Lanka, Hojai, Assam,
            India, 782446
          </Typography>
        </StyledBox>
        <StyledBox>
          <Typography variant="body1" gutterBottom>
            <strong>MAZID ALAM BARBHUYAN</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Director / Publication Manager, IFRD International LLP, Udali,
            Lanka, Hojai, Assam, India, 782446
          </Typography>
        </StyledBox>
        <StyledBox>
          <Typography variant="body1" gutterBottom>
            <strong>IFRD INTERNATIONAL LLP</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Sing Gaon, Udali, Lanka, Hojai, Assam, India, 782446
          </Typography>
        </StyledBox>
        <StyledBox>
          <Typography variant="body1" gutterBottom>
            <strong>INTERNATIONAL FEDERATION OF RESEARCH AND DISCOVERY</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Building no. 7, Borda street, Dahiat Al-Rasheed, Amman, Jordan,
            11831
          </Typography>
        </StyledBox>
        <StyledBox>
          <Typography variant="body2" gutterBottom>
            Email: {process.env.REACT_APP_IFRD_EMAIL}
          </Typography>
          <Typography variant="body2" gutterBottom>
            Phone: +91 6000605461
          </Typography>
        </StyledBox>
      </Box>
    </Box>
  );
};

export default ContactUs;
