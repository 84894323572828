import { Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
import InternationalFRDLLPLogo from "../../../assets/images/InternationalFRDllp.png";
import { ReactComponent as Facebook } from "../../../assets/images/Facebook.svg";
import { ReactComponent as Instagram } from "../../../assets/images/Instagram.svg";
import { ReactComponent as Youtube } from "../../../assets/images/Youtube.svg";
import { ReactComponent as Twitter } from "../../../assets/images/Twitter.svg";
import { ReactComponent as Linkedin } from "../../../assets/images/Linkedin.svg";
import {
  footerOthersArray,
  footerQuickLinksArray,
  socialMediaArray,
} from "../../../helpers/constant";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.surface,
  // //   borderRadius: theme.borderRadius[10],
  padding: "25px",
  // //   boxShadow: theme.boxShadow.shadowThree,
  // // marginBottom: "10px",
  // textAlign: "center",
  // display: "flex",
  // justifyContent: "space-between",
}));

const currentDate = new Date();

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <StyledBox>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Box>
            {/* <img
              src={InternationalFRDLLPLogo}
              alt="international-FRDLLP-logo"
              style={{
                width: "50%",
                paddingRight: "5px",
                backgroundColor: theme.palette.neutral[0],
                borderRadius: theme.borderRadius[10],
              }}
            /> */}
            {/* <Box sx={{ margin: "20px 0" }}>
              <Typography variant="body1" gutterBottom>
                <strong>Head Office</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                IFRD INTERNATIONAL LLP, Sing Gaon, Udali, Lanka, Hojai, Nagaon-
                782446, Assam, India
              </Typography>
            </Box> */}

            <Box sx={{ margin: "20px 0" }}>
              <Typography variant="body1" gutterBottom>
                <strong>Editorial Office</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Building no 7, Borda Street, Dahiat Al-Rasheed, Amman, Jordan,
                11831
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "space-around",
                width: "100%",
                gap: "20px",
              }}
            >
              <Typography variant="body1" gutterBottom>
                Follow us:
              </Typography>
              {socialMediaArray?.map((social, socialIndex) => (
                <Box key={`social_${socialIndex}`}>
                  {social?.imageTag === "Facebook" ? (
                    <Facebook style={{ width: "1.3rem", height: "1.3rem" }} />
                  ) : social?.imageTag === "Instagram" ? (
                    <Instagram style={{ width: "1.3rem", height: "1.3rem" }} />
                  ) : social?.imageTag === "Twitter" ? (
                    <Twitter style={{ width: "1.3rem", height: "1.3rem" }} />
                  ) : social?.imageTag === "Linkedin" ? (
                    <Linkedin style={{ width: "1.3rem", height: "1.3rem" }} />
                  ) : (
                    <Youtube style={{ width: "1.3rem", height: "1.3rem" }} />
                  )}
                </Box>
              ))}
            </Box>
            <a
              style={{
                fontSize: "14px",
              }}
              href="www.freepik.com"
              target="_blank"
            >
              Icons by Freepik
            </a>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Typography variant="h6" gutterBottom>
            Quick Links
          </Typography>
          <Box
            sx={{
              marginTop: "15px",
            }}
          >
            {footerQuickLinksArray?.map((quickLink, quickLinkIndex) => (
              <Typography
                key={`quick_link_${quickLinkIndex}`}
                sx={{ margin: "10px 0" }}
              >
                {quickLink.name}
              </Typography>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Typography variant="h6" gutterBottom>
            Others
          </Typography>
          <Box
            sx={{
              marginTop: "15px",
            }}
          >
            {footerOthersArray?.map((otherLink, otherLinkIndex) => (
              <Typography
                key={`quick_link_${otherLinkIndex}`}
                sx={{
                  margin: "10px 0",
                  cursor: "pointer",
                  "&:hover": {
                    color: theme.palette.info.main,
                    textDecoration: "underline",
                  },
                }}
                onClick={() => navigate(otherLink.route)}
              >
                {otherLink.name}
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Typography sx={{ margin: "10px 0", textAlign: "center" }}>
        {`IFRD International llp © ${currentDate.getFullYear()}`}
      </Typography>
    </StyledBox>
  );
};

export default Footer;
